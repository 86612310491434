/* Dropdown menu adjustments */
.dropdown-menu {
    position: absolute;
    top: 0;
    left: 105%; /* Position the dropdown menu to the right of the button */
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 4px;
    min-width: 250px; /* Minimum width to keep the layout consistent */
    width: auto; /* Automatically adjusts width to fit content */
    max-width: 100vw; /* Prevents dropdown from overflowing horizontally */
    padding: 10px;
    box-sizing: border-box; /* Prevents padding from adding to width */
  }
  
  /* Ensure the form group is positioned correctly */
  .form-group {
    position: relative; /* Ensure the dropdown menu is positioned relative to this container */
  }
  
  /* Dropdown table styles */
  .dropdown-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dropdown-table th,
  .dropdown-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  
  .dropdown-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .table-row {
    cursor: pointer;
  }
  
  .table-row:hover {
    background-color: #f1f1f1;
  }
  
  /* Button Container */
  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Continue Button */
  .continue-button {
    background-color: #9e1a2f; /* Epic's signature red */
    color: white;
    font-weight: bold;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 48%; /* Make buttons take equal space */
  }
  
  .continue-button:hover {
    background-color: #6c0e1a; /* Darker shade on hover */
  }
  
  /* Cancel Button */
  .cancel-button {
    background-color: #dedede; /* Light gray background */
    color: #333; /* Dark text */
    font-weight: bold;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 48%; /* Make buttons take equal space */
  }
  
  .cancel-button:hover {
    background-color: #b3b3b3; /* Darker gray on hover */
  }
  