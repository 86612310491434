body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('./background.jpg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./background.jpg') no-repeat center center;
  background-size: cover;
  filter: blur(8px);
  z-index: -1; /* Keep background behind everything */
}

.login-box {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  width: 100%;
  max-width: 600px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.login-logo {
  position: absolute;
  width: 35%;
  top: -7%; /* Adjusted to move it down from -7% to 5% to fit better */
  left: 50%;
  transform: translateX(-50%);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add a shadow to make it pop against the background */
  z-index: 1; /* Logo behind the form */
}

.login-form {
  margin-top: 20px; /* Give space between the logo and form */
  margin-bottom: -50px; /* Prevent the form from covering the logo by pulling it up */
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 1; /* Keep the form on top of the logo */
}

.form-group {
  text-align: left;
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-input {
  width: 400px;
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.form-input::placeholder {
  color: #888;
}

.form-input:focus {
  border-color: #007bff;
  outline: none;
}

.login-button {
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

.login-footer {
  margin-top: 20px;
}

.login-link {
  color: #007bff;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.logout-button:hover {
  background-color: #d32f2f;
}

.configure-icon-container {
  position: absolute;
  bottom: 20px; /* Adjust the distance from the bottom */
  left: 20px; /* Adjust the distance from the left */
  z-index: 10; /* Ensure it appears above other content */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Change cursor to pointer for interactivity */
}

.configure-icon-container svg {
  color: #0077c2; /* Customize icon color */
  transition: color 0.2s ease; /* Smooth transition on hover */
}

.configure-icon-container:hover svg {
  color: #005a9c; /* Change color on hover */
}
